import * as React from "react"
import {Link} from 'gatsby'
import Logo from '../images/logos/5thStrand-Cutout-White.svg'
import Layout from '../components/layout'
import PinkFullCircle from '../images/pink-full-circle.svg'
import YellowFullCircle from '../images/yellow-full-circle.svg'
import eye from '../images/animated/eye-outline.gif'
import pen from '../images/animated/35-edit-outline.gif'
import chat from '../images/animated/consultation-outline.gif'
import glasses from '../images/animated/glasses-eye-blink-outline.gif'


import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

const Values = () => {
  return (

    <Layout>
      <CustomCursor
      targets={['.link', '.your-css-selector']}
      customClass='custom-cursor'
      dimensions={50}
      fill='#FFD600'
      opacity={1}
      smoothness={{
        movement: 0.2,
        scale: 0.1,
        opacity: 1,
      }}
      targetOpacity={0.5}
    />
    <main className="cont bg-5th-pink nocursor scroll-smooth	">
      <div className="child sm:text-center flex justify-center items-center relative">
        <div className="w-5/6">
          <h1 className=" font-bold text-black text-4xl lg:text-8xl font-heading pb-6">
          WHAT’S <span className="">IMPORTANT</span> TO US<span className="text-5th-yellow"> .</span>
          </h1>
          <p className="text-xl lg:text-3xl text-white font-heading">Here are four kind words that sum us up.
          </p>
        </div>

        <div className="top-0 left-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={PinkFullCircle}/>
        </div>

        <div className="top-0 right-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={YellowFullCircle}/>
        </div>

        <div className="bottom-10 right-10 absolute ">
          <p className="text-black">PART OF THE <a target="_blank" href="https://urbanscaleinterventions.com/"><span className="link text-5th-yellow">USI</span></a> FAMILY</p>
        </div>
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left bg-5th-grey">
      <div className="w-5/6">
        <h2 className="font-heading text-4xl text-5th-pink lg:text-6xl pt-24">FOUR KIND WORDS <span className="text-5th-yellow">.</span></h2>
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16">
          <div className="font-heading">
            <h2 className="font-heading text-4xl text-5th-pink lg:text-6xl pb-4"><span className="text-8xl lg:text-9xl">1</span> CURIOUS </h2>
            <p className="text-md lg:text-xl font-lower text-white">
            The pursuit of knowledge is a fancy way to say we like to learn and we think learning never really stops. 
            Being curious, open-minded about designs, technology, methods and structure underline our approach to managing projects.
            </p>
          </div>

          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={eye}/>
          </div>
        </div>

      </div> 
      </div>


      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left bg-5th-grey">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16">
          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={pen}/>
          </div>
          <div className="font-heading">
            <h2 className="font-heading text-4xl text-5th-yellow lg:text-6xl pb-4">CREATIVE <span className="text-9xl">2</span></h2>
            <p className="text-md lg:text-xl font-lower text-white">
            Trying something new can be intimidating, but allowing yourself the space to try something new is where our best work comes from. 
            There is no formula for creativity but we take the steps to create something exceptional and unique. 
            </p>
          </div>
        </div>

        
       
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left bg-5th-grey">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16">
          <div className="font-heading">
            <h2 className="font-heading text-4xl text-5th-cyan lg:text-6xl pb-4"><span className="text-9xl">3</span> CARING</h2>
            <p className="text-md lg:text-xl font-lower text-white">
            We strive for the new and uncaptured, the unique and experimental. 
            However, they have to be tied to responsibility and consistency. 
            Through all our projects we have quality control mechanisms and acknowledge the trust placed in us to meet our standards. 
            </p>
          </div>

          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={chat}/>
          </div>
        </div>
       
      </div> 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left bg-5th-grey">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16">
          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={glasses}/>
          </div>
          <div className="font-heading">
            <h2 className="font-heading text-4xl text-white lg:text-6xl pb-4">CLARITY <span className="text-9xl">4</span></h2>
            <p className="text-md lg:text-xl font-lower text-white">
            The way we approach all of our work is through clear and reliable communication. 
            Being available, present and honest in our collaborations is a key part of our process.
            Clarity enables trust and making sure no one is suprised by the outcome of a piece of work. 
            We co-design products and take you along every step of the way with us.
            </p>
          </div>
        </div>

       
      </div>

      <div className="w-full bg-5th-grey">
      <footer className="snap-always snap-center p-4 bg-5th-grey sm:p-6  mx-auto w-5/6 font-lower">
            <div className="md:flex md:justify-between items-center">
                <div className="mb-6 md:mb-0">
                    <Link to="/" className="flex items-center">
                        <img src={Logo} className="h-36" alt="5th Strand Logo"/>
                    </Link>
                </div>
                <div className="mb-6 md:mb-0">
                  <h1 className="text-white text-2xl sm:text-3xl md:text-4xl leading-relaxed font-bold ">5th Strand<br/><span className='font-normal leading-relaxed'>Believers in Change</span></h1>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-8">
                   
                   
                    <div>
                        <h2 className="mb-6 text-xs font-semibold text-white uppercase">Legal</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="mb-6 font-semibold text-white uppercase text-xs">Contact</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <a href="mailto:studio@5thstrand.com" className="hover:underline text-xs">studio@5thstrand.com</a>
                            </li>
                            <li>
                                <a href="tel:+447754078144" className="hover:underline">Tel: 077 540 78144</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-white sm:text-center ">© 2022 <a href="" className="hover:underline">5th Strand USI</a>. All Rights Reserved.
                    </span>
                  
                </div>
        </footer>
        </div>

    
    </main>
    </Layout>
  )
}

export default Values

export function Head() {
  return (
    <>
      <title>5th Strand - Values</title>
      <meta name="description" content="5th Strand has four core values." />
        <meta property="og:title" content="5th Strand - Values" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.5thstrand.com/values" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </>  
    )
}